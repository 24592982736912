/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Slideshow, Column, ColumnWrap, ColumnWrapper, Image, Title, Button, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Slideshow name={"o1h3r5jmsqs"} infinite={true} autoplay={true}>
          
          <Column className="css-1nonwes js-anim  --anim4 --anim-s3 --full pb--60" style={{"paddingTop":3}} fullscreen={true} anim={"4"} animS={"3"} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=3000x_.jpg);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper className="--shape5 js-anim  --anim4 --anim-s3 --shadow4" style={{"marginTop":0}} animS={"3"} anim={"4"}>
                
                <Image style={{"maxWidth":694,"marginBottom":60}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=2000x_.png 2000w"} alt={""} src={"https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=860x_.png"}>
                </Image>

                <Title className="title-box ff--3 fs--43 w--400 title-box--invert ls--0 lh--12" style={{"marginTop":7}} content={"Jedinečné místo pro svatby, firemní i kulturní akce<br>s možností ubytování přímo v areálu."}>
                </Title>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 ff--3 fs--20" style={{"backgroundColor":"var(--white)","marginTop":64}} content={"<span style=\"color: rgb(0, 0, 0);\">Co vše nabízíme</span>"} use={"page"} href={"/#onasnadpis"}>
                </Button>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="css-1lhvh1q js-anim  --anim4 --anim-s3 --full pb--60" style={{"paddingTop":3}} fullscreen={true} anim={"4"} animS={"3"} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=3000x_.jpg);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper className="--shape5 js-anim  --anim4 --anim-s3 --shadow4" style={{"marginTop":0}} animS={"3"} anim={"4"}>
                
                <Image style={{"maxWidth":694,"marginBottom":60}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=2000x_.png 2000w"} alt={""} src={"https://cdn.swbpg.com/t/7361/ed6642e5f7eb4be5a75c8fae028b9f19_s=860x_.png"}>
                </Image>

                <Title className="title-box ff--3 fs--43 w--400 title-box--invert ls--0 lh--12" style={{"marginTop":7}} content={"Dokonalé místo pro Váš nejšťastnější den,<br>zámek Vidžín.<br>"}>
                </Title>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 ff--3 fs--20" style={{"backgroundColor":"var(--white)","marginTop":64}} content={"<span style=\"color: rgb(0, 0, 0);\">Informace ke svatbám</span>"} use={"page"} href={"/svatby#svatbyaoslavy"}>
                </Button>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column className="--center" style={{"paddingBottom":0,"paddingTop":79}} name={"onasnadpis"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Co vám můžeme nabídnout"}>
              </Title>

              <Text className="text-box text-box--center fs--20" content={"<span style=\"color: var(--color-custom-1);\">Obklopený lesy a loukami západních Čech se nachází malebný barokní zámek Vidžín, místo s jedinečným geniem loci, kde se prolíná historie&nbsp;s moderním využitím. Zámek je ideálním místem pro svatby, firemní akce, výstavy i kulturní události <br>– to vše v kouzelném prostředí,&nbsp;&nbsp;s komfortním zázemím a možností ubytování až 145 osob.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" layout={"l4"} style={{"paddingTop":104}} name={"onasnabidka"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/f23b6e3a1efd43318f19d7127bcfca7f_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/7361/f23b6e3a1efd43318f19d7127bcfca7f_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/f23b6e3a1efd43318f19d7127bcfca7f_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/f23b6e3a1efd43318f19d7127bcfca7f_s=860x_.png 860w"}>
              </Image>

              <Title className="title-box title-box--center fs--36 lh--14" content={"Svatby<br>a oslavy<br>"}>
              </Title>

              <Text className="text-box fs--20" content={"<span style=\"color: var(--color-custom-1);\">Plníme Váš sen o nezapomenutelné svatbě. V krásném romantickém prostředí, s ubytováním pro všechny hosty&nbsp;a za bezkonkurenční cenu!</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 fs--20 lh--14" style={{"backgroundColor":"rgba(87,59,143,1)"}} content={"Více informací<br>ke svatbám<br>"} use={"page"} href={"/svatby#svatbyinfo"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/2d66f4af9eed420ebc8e387f7a833faa_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/7361/2d66f4af9eed420ebc8e387f7a833faa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/2d66f4af9eed420ebc8e387f7a833faa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/2d66f4af9eed420ebc8e387f7a833faa_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/2d66f4af9eed420ebc8e387f7a833faa_s=1400x_.jpg 1400w"}>
              </Image>

              <Title className="title-box title-box--center fs--36 lh--14" content={"Pronájem<br>a ubytování<br>"}>
              </Title>

              <Text className="text-box text-box--center fs--20" style={{"marginTop":64}} content={"<span style=\"color: var(--color-custom-1);\">Zámek Vidžín je vyhledávaným místem pro pořádání svatebních víkendů, firemních akcí, výstav i kulturních událostí.</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 btn-box--center fs--20 lh--14" style={{"backgroundColor":"rgba(87,59,143,1)"}} content={"Více o pronájmu <br>a ubytování"} use={"page"} href={"/ubytovani#ubytovaniapronajem"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/efd4870da90b47e48792c3a53e050b08_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/7361/efd4870da90b47e48792c3a53e050b08_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/efd4870da90b47e48792c3a53e050b08_s=660x_.jpg 660w"}>
              </Image>

              <Title className="title-box fs--36 lh--14" style={{"maxWidth":398,"paddingRight":0,"paddingLeft":0}} content={"Zámecké expozice<br>a prohlídky"}>
              </Title>

              <Text className="text-box text-box--center fs--20" content={"<span style=\"color: var(--color-custom-1);\">K vidění jsou barokní interiéry<br>s vybavením z období 18. století, ideální&nbsp;například pro svatební focení nebo pro filmové natáčení.</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 btn-box--center fs--20 lh--14" style={{"backgroundColor":"rgba(87,59,143,1)","marginTop":40}} content={"Informace o zámku <br>a jeho historii"} use={"page"} href={"/ozamku"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1yezbqi --parallax" name={"prohlidky"} style={{"paddingTop":138,"paddingBottom":160}} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3 pb--10 pt--10" columns={"3"}>
            
            <ColumnWrapper className="--shadow4 pb--30 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)","paddingRight":54,"paddingLeft":50}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":266,"paddingRight":0}} content={"Prohlídky<br>zámku<br>"}>
              </Title>

              <Text className="text-box text-box--center fs--20" style={{"maxWidth":232,"paddingRight":0,"paddingLeft":0}} content={"<span style=\"color: var(--color-custom-1);\">Prohlídka zámku Vidžín probíhá v přízemí zámecké budovy, <br>kde se nachází historické interiéry z 18. století. Během okruhu navštívíte vstupní halu, reprezentační sál, zámeckou kuchyni,&nbsp;tři barokní ložnice, rokokovou pracovnu <br>a kapli.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow4 pb--30 pl--40 pr--40 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Title className="title-box fs--36" content={"35 min"}>
              </Title>

              <Text className="text-box fs--20 mt--10" content={"<span style=\"color: var(--color-custom-1);\">délka prohlídky <br>s výkladem.</span>"}>
              </Text>

              <Text className="text-box fs--20" style={{"marginTop":44}} content={"<span style=\"color: var(--color-custom-1);\">Návštěva je možná <br>po předchozí telefonické domluvě nebo dle aktuálních termínů, které zveřejňujeme <br>zde na webu.</span>"}>
              </Text>

              <Text className="text-box text-box--center fs--20" style={{"marginTop":86}} content={"<span style=\"color: var(--color-custom-1);\">Parkování přímo <br>před zámeckou branou.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow4 pb--30 pl--40 pr--40 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Title className="title-box fs--36" content={"150 Kč"}>
              </Title>

              <Text className="text-box fs--20" style={{"marginTop":21}} content={"<span style=\"color: var(--color-custom-1);\">dospělí</span>"}>
              </Text>

              <Title className="title-box fs--36" style={{"marginTop":66}} content={"100 Kč"}>
              </Title>

              <Text className="text-box text-box--center fs--20" style={{"marginTop":23}} content={"<span style=\"color: var(--color-custom-1);\">děti 10 až 17 let<br>děti do 9 let zdarma</span>"}>
              </Text>

              <Text className="text-box fs--20" style={{"marginTop":59}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">Hledáme generálního sponzora</span> pro zajištění provozu prohlídkové trasy na zámku Vidžín <br>v sezóně 2025.\n</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"#dbd6f3","paddingTop":51,"paddingBottom":46}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" style={{"marginTop":0}} content={"Odkazy"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":334,"marginTop":30}} content={"<a href=\"/nf-vidzin#nfvidzinuvod\" style=\"color: var(--color-custom-1);\">Nadační fond Vidžín</a>"}>
              </Text>

              <Text className="text-box text-box--center fs--22" style={{"marginTop":19}} content={"<a href=\"/#prohlidky\" style=\"color: var(--color-custom-1);\">Prohlídky zámku</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" content={"Zámek Vidžín"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":311,"marginTop":21}} content={"<span style=\"color: var(--color-custom-1);\">Nadační fond Vidžín\n<br>Vidžín č.8, 330 40 Úterý<br><span style=\"font-weight: bold;\">tel:&nbsp;602 625 691</span></span>"}>
              </Text>

              <Image className="--center" style={{"maxWidth":196,"marginTop":27,"paddingLeft":33}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=1400x_.png 1400w"} alt={"logo zámek Vidžín"} src={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--36" style={{"marginTop":0}} content={"Sociální sítě"}>
              </Title>

              <Image style={{"maxWidth":40,"paddingLeft":0,"marginTop":31}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png 350w"} alt={"facebook"} src={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.facebook.com/zameckafaravidzin"} url={"https://www.facebook.com/zameckafaravidzin"}>
              </Image>

              <Image style={{"maxWidth":38,"paddingLeft":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/zamekvidzin/"} url={"https://www.instagram.com/zamekvidzin/"}>
              </Image>

              <Image style={{"maxWidth":42}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.youtube.com/watch?v=aIwoNcyrEC4"} url={"https://www.youtube.com/watch?v=aIwoNcyrEC4"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}